import { uiState } from "@/contexts/uiState";
import Bugsnag from "@bugsnag/js";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { Box, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

class ImageLoadError extends Error {
  constructor(message) {
    super(message);
    this.name = "ImageLoadError";
  }
}

interface ISectionProofImage {
  proofImage: string;
  href?: string;
}

const SectionProofImage: React.FC<ISectionProofImage> = ({
  proofImage,
  href,
}: ISectionProofImage) => {
  const [ui, setState] = useRecoilState(uiState);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const bgColor = ui.isLight ? ui.color1 : ui.color3;
  const gradientColor = ui.isLight ? ui.color2 : ui.color4;

  const isCheckeredStyles = ui.isCheckered
    ? {
        padding: "20px",
        backgroundColor: bgColor,
        backgroundImage: `
    linear-gradient(45deg, ${gradientColor} 25%, transparent 25%),
    linear-gradient(-45deg, ${gradientColor} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${gradientColor} 75%),
    linear-gradient(-45deg, transparent 75%, ${gradientColor} 75%)
  `,
        backgroundSize: `36px 36px`,
        backgroundPosition: `0 0, 0 18px, 18px -18px, -18px 0px`,
      }
    : {};

  const carouselStyles = loading ? null : isCheckeredStyles;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        ...carouselStyles,
      }}
    >
      {loading && !error && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="300px"
          sx={{ minWidth: 760 }}
        />
      )}
      {error ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "grey.200",
          }}
        >
          <BrokenImageIcon color="error" />
          <Typography variant="body2" color="error" sx={{ marginLeft: 1 }}>
            Image failed to load. Try reloading the page.
          </Typography>
        </Box>
      ) : (
        <a href={href || proofImage} target="_blank">
          <img
            src={proofImage}
            alt="Proof image"
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              setLoading(false);
              setError(true);

              Bugsnag.notify(new Error("Proof images failed to load"));
            }}
            style={{
              display: loading ? "none" : "block", // Hide image until it loads or errors
            }}
          />
        </a>
      )}
    </Box>
  );
};

export default React.memo(SectionProofImage);
